import { communeTypes } from "./commune.types";

const INITIAL_STATE = {
  itemList: [],
  itemSelected: {},
  loading: false,
  errors: "",
};

const communeReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case communeTypes.GET_ALL_COMMUNE:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case communeTypes.COMMUNE_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

      case communeTypes.GET_SELECTED_COMMUNE:
      return {
        ...state,
        itemSelected: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default communeReducer;
