import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// import bag from "@/assets/images/bag.png";
import HomeIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import "./SafeErrorBoundaries.style.scss";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { ToastSuccess } from '@/utils/toast';

const ErrorFallback = ({error}) => {

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goTo = () => {
    navigate(`../`);
  } 

  const sendReport = () => {
    console.log('error',error);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      ToastSuccess.fire();
      goTo();
    }, 2000);
  }
  
  return (
    <div className="error-boundary-container">
        <div className="middle-card">
          <div className="oops">
            <span>O</span>
            <span>😅</span>
            {/* <img src={bag} alt="" /> */}
            <span>P</span>
            <span>S</span>
          </div>
          <p> 
            😅 <b>Désolé!</b> Une erreur s'est produite ...
            <br/>
            <small>Veuillez effectuer l'une des actions suivantes</small>
          </p>
          <div className="option">
            <Button variant="contained" color="primary" startIcon={<HomeIcon />}  
                    onClick={ goTo } >
              Fermer l'erreur
            </Button>
            <Button variant="outlined" color="inherit" endIcon={<SendIcon />}
                    onClick={ sendReport } >
              Rapporter l'erreur
            </Button>
          </div>
          <Loader isLoading={isLoading} />
        </div>
    </div>
  );
};

const myErrorHandler = ({error, info}) => {
  //console.log('error',error);
  //console.log('info',info);
}



export  const SafeErrorBoundaries = ({children}) => {

  return (
    <ErrorBoundary 
        onError={myErrorHandler}
        FallbackComponent={ErrorFallback}>
        {children}
    </ErrorBoundary>
  )
}
