import { authTypes } from "./auth.types";
const items = localStorage.getItem("persist:root");

let data = JSON.parse(items);
let theme = null;
if (data) {
  theme = JSON.parse(data?.auth);
}
let skin = theme?.skin;

const INITIAL_STATE = {
  user: null,
  password: null,
  jwt: "",
  skin: skin ? skin : "apple",
  loading: false,
  rememberMe: false,
  errors: "",
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authTypes.GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        errors: "",
      };

    case authTypes.GET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

    case authTypes.GET_USER_TOKEN:
      return {
        ...state,
        jwt: action.payload,
      };

    case authTypes.AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case authTypes.AUTH_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case authTypes.REMEMBER_ME_AUTH:
      return {
        ...state,
        rememberMe: action.payload,
      };

    case authTypes.LOGOUT:
      return {
        ...state,
        user: null,
        loading: false,
        rememberMe: false,
        jwt: "",
        errors: "",
      };

    case authTypes.SELECT_SKIN:
      state.loading = true;
      return {
        ...state,
        skin: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
