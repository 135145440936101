export const compteTypes = {
    COMPTE_LOADING : "COMPTE_LOADING",
    COMPTE_DETAIL_LOADING : "COMPTE_DETAIL_LOADING",
    COMPTE_CREATE_LOADING : "COMPTE_CREATE_LOADING",
    COMPTE_UPDATE_LOADING : "COMPTE_UPDATE_LOADING",
    COMPTE_CREATE : "COMPTE_CREATE",
    COMPTE_UPDATE : "COMPTE_UPDATE",
    COMPTE_UPDATE_PASSWORD : "COMPTE_UPDATE_PASSWORD",
    GET_ALL_COMPTE : "GET_ALL_COMPTE",
    GET_SELECTED_COMPTE : "GET_SELECTED_COMPTE",
};
  
  