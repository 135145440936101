/* eslint-disable react/jsx-pascal-case */
import { Routes, Route, Navigate } from "react-router-dom";
import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { lazyImport } from '@/utils/lazyImport';
import { routePaths } from "@/routes/routesPath";



const  Allocation_espace  = lazyImport(
    () => import("@/pages/user-space/espace/allocation-espace/AllocationEspace")
);
// const  Estimation_espace  = lazyImport(
//     () => import("@/pages/user-space/espace/estimation/EstimationCout")
// );
// const  Reamenagement_espace  = lazyImport(
//     () => import("@/pages/user-space/espace/reamenagement/Reamenagement")
// );



// export const EspaceRoutes = () => {
//     return (
//         <Routes>
//             <Route path={routePaths.espace_allocation} element={
//                 <SafeErrorBoundaries>
//                     <Allocation_espace />
//                 </SafeErrorBoundaries>
//             } />

//             <Route path='' element= {  <Navigate to={routePaths.espace_allocation}/>  } />
//         </Routes>
//     )
// }
// export const EspaceRoutes = () => {
//     return (
//         <Routes>
//             <Route path={routePaths.espace_reamenagement} element={
//                 <SafeErrorBoundaries>
//                     <Estimation_espace />
//                 </SafeErrorBoundaries>
//             } />

//             <Route path='' element= {  <Navigate to={routePaths.espace_reamenagement}/>  } />
//         </Routes>
//     )
// }
export const EspaceRoutes = () => {
    return (
        <Routes>
            <Route path={routePaths.espace_allocation} element={
                <SafeErrorBoundaries>
                    <Allocation_espace />
                </SafeErrorBoundaries>
            } />

            <Route path='' element= {  <Navigate to={routePaths.espace_allocation}/>  } />
        </Routes>
    )
}