import { AuthService } from "../../../services/auth.service";
import { authTypes } from "./auth.types";

export const getUser = (user) => ({
  type: authTypes.GET_USER,
  payload: user,
});

export const getPassword = (password) => ({
  type: authTypes.GET_PASSWORD,
  payload: password,
});

export const getUserToken = (token) => ({
  type: authTypes.GET_USER_TOKEN,
  payload: token,
});

export const setLoadingAuth = (loading) => ({
  type: authTypes.AUTH_LOADING,
  payload: loading
});

export const setErrorsAuth = (errors) => ({
  type: authTypes.AUTH_ERRORS,
  payload: errors,
});

export const setRememberMeAuth = (isRememberMe) => ({
  type: authTypes.REMEMBER_ME_AUTH,
  payload: isRememberMe,
});

export const logout = () => ({
  type: authTypes.LOGOUT,
});

export const selectSkin = (skin) => ({
  type: authTypes.SELECT_SKIN,
  payload: skin,
});


export const  loginAsync =  ({email, password}) =>
(dispatch) => {
  dispatch(setLoadingAuth(true));
  AuthService.authenticate({email, password});
};

export const  forgetPassAsync =  ({email}) =>
(dispatch) => {
  dispatch(setLoadingAuth(true));
  // console.log("Email:: ",email)
  AuthService.forgottenPass({email});
};

export const  resetPassAsync =  ({password, resettoken}) =>
(dispatch) => {
  dispatch(setLoadingAuth(true));
  AuthService.resetPass({password, resettoken});
};

export const  logoutAsync =  () =>
(dispatch) => {
  dispatch(logout());
};


