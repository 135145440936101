import { gradeTypes } from "./grade.types";

const INITIAL_STATE = {
  itemList: [],
  loading: false,
  itemSelected: {},
  loadingDetail: false,
  errors: "",
};

const gradeReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case gradeTypes.GET_ALL_GRADE:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case gradeTypes.GRADE_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case gradeTypes.GET_SELECTED_GRADE:
      return {
        ...state,
        itemSelected: action.payload,
        loading: false,
      };

    case gradeTypes.GRADE_DETAIL_LOADING:
      return {
        ...state,
        loadingDetail: action.payload,
        errors: "",
      };

    default:
      return state;
  }
};

export default gradeReducer;
