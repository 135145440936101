/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-pascal-case */
// import React from "react";
// import "./Espace.style.scss";
// // import info from "../../../assets/mapIcon/info.png";
// // import MyTabs from '../../../components/tabs-setting/MyTabs';
// // import AllocationEspace from "./allocation-espace/AllocationEspace";
// // import Reamenagement from "./reamenagement/Reamenagement";
// // import EstimationCout from "./estimation/EstimationCout";
// import { Center } from '@/styles';

// const Espace = () => {

//   return (
//     <Center>
//     {/* <div className="main-espace">
//       <span className="titre">Gestion d'espace</span>
//       <div className="content-page"> */}

//             {/* <div className="loader-action">
//               <img src={info} alt="" height="50px" />
//               <span>Aucune action effectuée</span>
//             </div> */}
        
//         {/* <MyTabs headers={['Allocation d\'espace', 'Travaux de réamenagement','Estimation de côut de terrain']} pages={[<AllocationEspace />,<Reamenagement />,<EstimationCout />]} />
          
//       </div>
//     </div> */}
//      <div className="construction">
//         <div className="nothing">
//           <div className="logo-main">
//             <div className="logo-letter">GP</div>
//             <div className="logo-name">
//               <div className="logo-name1">GESTION</div>
//               <div className="logo-name2">PATRIMOINE</div>
//             </div>
//           </div>
//           <span>En construction</span>
//         </div>
//       </div>
//     </Center>
//   );
// };

// export default Espace;


import { EspaceRoutes } from "./routes"

export const Espace = () => {
    return (
        <EspaceRoutes />
    )
}