import { dashboardTypes } from "./dashboard.types";

const INITIAL_STATE = {
  dataForPersonPerOffice: [],
  dataCommuneStat: [],
  dataStatsByAffectation: [],
  dataAreaByJob: [],
  dataLocalByDirectionAndLevel: [],
  dataOfficePartByTypeFonction: [],
  dataTypeBuildingBySite: [],
  dataGlobalStats: [],
  loading: false,
  errors: "",
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case dashboardTypes.GET_ALL_DASHBOARD_COMMUNE_STAT:
      return {
        ...state,
        dataCommuneStat: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_FOR_PERSON_PER_OFFICE:
      return {
        ...state,
        dataForPersonPerOffice: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_STAT_BY_AFFECTATION:
      return {
        ...state,
        dataStatsByAffectation: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_AREA_BY_JOB:
      return {
        ...state,
        dataAreaByJob: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_LOCAL_BY_DIRECTION_AND_LEVEL:
      return {
        ...state,
        dataLocalByDirectionAndLevel: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_OFFICE_PART_BY_TYPE_FONCTION:
      return {
        ...state,
        dataOfficePartByTypeFonction: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_TYPE_BUILDING_BY_SITE:
      return {
        ...state,
        dataTypeBuildingBySite: action.payload,
        loading: false,
      };

    case dashboardTypes.GET_ALL_DASHBOARD_GLOBAL_STATS:
      return {
        ...state,
        dataGlobalStats: action.payload,
        loading: false,
      };

    case dashboardTypes.DASHBOARD_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    default:
      return state;
  }
};

export default dashboardReducer;
