import { localTypes } from "./local.types";

const INITIAL_STATE = {
  itemList: [],
  loading: false,
  itemSelected: {},
  loadingDetail: false,
  itemTypeList: [],
  itemTypeOccuList: [],
  typeLoading: false,
  errors: "",
};

const localReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case localTypes.GET_ALL_LOCAL:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };
      
    case localTypes.GET_ALL_LOCAL_OCCU:
      return {
        ...state,
        itemTypeOccuList: action.payload,
        loading: false,
      };

    case localTypes.LOCAL_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case localTypes.GET_SELECTED_LOCAL:
      return {
        ...state,
        itemSelected: action.payload,
        loading: false,
      };

    case localTypes.LOCAL_DETAIL_LOADING:
      return {
        ...state,
        loadingDetail: action.payload,
        errors: "",
      };

    case localTypes.GET_ALL_TYPE_LOCAL:
    return {
      ...state,
      itemTypeList: action.payload,
      typeLoading: false,
    };

    case localTypes.LOCAL_TYPE_LOADING:
      return {
        ...state,
        typeLoading: action.payload,
        errors: "",
      };

    default:
      return state;
  }
};

export default localReducer;
