import React from 'react';
import classnames from 'classnames';
import "./Loader.style.scss";
import { CircularProgress } from '@mui/material';


export const Loader = ({isLoading}) => {

  return (
    <div className={classnames('loader-container', {'active':isLoading})}>
            <span> Chargement </span>
            <CircularProgress />
    </div>
  )
}
