import React from 'react';
import { createRoot } from "react-dom/client";
import './index.style.scss';

import App from './app/App.jsx';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
    <App />
  // </StrictMode>
);