import { directionTypes } from "./direction.types";

const INITIAL_STATE = {
  itemList: [],
  itemSelected: {},
  loading: false,
  errors: "",
};

const directionReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case directionTypes.GET_ALL_DIRECTION:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case directionTypes.DIRECTION_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case directionTypes.GET_SELECTED_DIRECTION:
      return {
        ...state,
        itemSelected: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default directionReducer;
