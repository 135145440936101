export const dashboardTypes = {
    DASHBOARD_LOADING : "DASHBOARD_LOADING",
    GET_ALL_DASHBOARD_FOR_PERSON_PER_OFFICE : "GET_ALL_DASHBOARD_FOR_PERSON_PER_OFFICE",
    GET_ALL_DASHBOARD_COMMUNE_STAT : "GET_ALL_DASHBOARD_COMMUNE_STAT",
    GET_ALL_DASHBOARD_STAT_BY_AFFECTATION : "GET_ALL_DASHBOARD_STAT_BY_AFFECTATION",
    GET_ALL_DASHBOARD_AREA_BY_JOB : "GET_ALL_DASHBOARD_AREA_BY_JOB",
    GET_ALL_DASHBOARD_LOCAL_BY_DIRECTION_AND_LEVEL: "GET_ALL_DASHBOARD_LOCAL_BY_DIRECTION_AND_LEVEL",
    GET_ALL_DASHBOARD_OFFICE_PART_BY_TYPE_FONCTION: "GET_ALL_DASHBOARD_OFFICE_PART_BY_TYPE_FONCTION",
    GET_ALL_DASHBOARD_TYPE_BUILDING_BY_SITE: "GET_ALL_DASHBOARD_TYPE_BUILDING_BY_SITE",
    GET_ALL_DASHBOARD_GLOBAL_STATS: "GET_ALL_DASHBOARD_GLOBAL_STATS",
};
  
  