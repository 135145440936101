export const routePaths = {
    espace_connexion: "/espace_connexion",
    signin: "connexion",
    signup: "inscription",
    forget: "mot_oublie",
    reset_password: "reinitialiser_mot_de_passe",

    espace_user: "/accueil",
    changePassword: "change_pass",
    carte: "carte",
    dashboard: "dashboard",
    espace: 'gestion-espace',
    portail: 'portail',
    parametre: 'parametre',
    compte: 'compte',
    compte_list: 'compte/liste',
    compte_creation: 'compte/formulaire',

    param_pole: 'pole',
    param_direction: 'direction',
    param_commune: 'commune',
    param_profil: 'profil',
    param_site: 'site',
    param_batiment: 'batiment',
    param_local: 'local',
    param_salle: 'salle',
    param_personnel: 'personnel',

    espace_allocation: 'allocation-espace',
    espace_estimation: 'estimation',
    espace_reamenagement: 'reamenagement',
};