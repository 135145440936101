import { Routes, Route, Navigate } from "react-router-dom";
import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { routePaths } from "@/routes/routesPath";
import { lazyImport } from '@/utils/lazyImport';
import { ParametreSpace } from "@/pages/user-space/parametre/ParametreSpace";
import { Espace } from "@/pages/user-space/espace/Espace";
import { selectAuthPass } from '@/store/reducers/auth/auth.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

const  ChangePassword  = lazyImport(
    () => import("@/pages/user-space/change-password/ChangePassword")
);

const  Carte  = lazyImport(
    () => import("@/pages/user-space/carte/Carte")
);

const  Dashboard  = lazyImport(
    () => import("@/pages/user-space/dashboard/Dashboard")
);

// const  Espace  = lazyImport(
//     () => import("@/pages/user-space/espace/Espace")
// );


// const  PortailVirtuel  = lazyImport(
//     () => import("@/pages/user-space/portail-virtuel/PortailVirtuel")
// );

// const  Compte  = lazyImport(
//     () => import("@/pages/user-space/compte/Compte")
// );

const  CompteList  = lazyImport(
    () => import("@/pages/user-space/compte/compte-list/CompteList")
);

const  CompteCreate  = lazyImport(
    () => import("@/pages/user-space/compte/compte-creation/CompteCreation")
);

// const  ParametreSpace  = lazyImport(
//     () => import("@/pages/user-space/parametre/ParametreSpace")
// );


const UserSpaceRoutesPage = ({setFull , password}) => {
    return (
        <Routes>
            <Route path={routePaths.changePassword} element={
                <SafeErrorBoundaries>
                    <ChangePassword />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.carte} element={
                <SafeErrorBoundaries>
                    <Carte setFull={setFull} />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.dashboard} element={
                <SafeErrorBoundaries>
                    <Dashboard />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.espace+'/*'} element={
                <SafeErrorBoundaries>
                    <Espace />
                </SafeErrorBoundaries>
            } />
            {/* <Route path={routePaths.portail} element={
                <SafeErrorBoundaries>
                    <PortailVirtuel />
                </SafeErrorBoundaries>
            } /> */}
            {/* <Route path={routePaths.compte} element={
                <SafeErrorBoundaries>
                    <Compte />
                </SafeErrorBoundaries>
            } /> */}
            <Route path={routePaths.compte} element={
                <SafeErrorBoundaries>
                    <CompteList />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.compte_creation} element={
                <SafeErrorBoundaries>
                    <CompteCreate />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.parametre+'/*'} element={
                <SafeErrorBoundaries>
                    <ParametreSpace />
                </SafeErrorBoundaries>
            } />
            <Route path='' element= {  <Navigate to={ password === ("Patrimoine2023#") ? routePaths.changePassword : routePaths.carte }/>  } />
        </Routes>
    )
}

const mapStateToProps = createStructuredSelector({
    password : selectAuthPass,
  });

const connector = connect(mapStateToProps);
export const UserSpaceRoutes = connector(UserSpaceRoutesPage);


