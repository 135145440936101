export const localTypes = {
    LOCAL_LOADING : "LOCAL_LOADING",
    LOCAL_TYPE_LOADING : "LOCAL_TYPE_LOADING",
    LOCAL_DETAIL_LOADING : "LOCAL_DETAIL_LOADING",
    LOCAL_CREATE_LOADING : "LOCAL_CREATE_LOADING",
    LOCAL_CREATE : "LOCAL_CREATE",
    GET_ALL_LOCAL : "GET_ALL_LOCAL",
    GET_ALL_LOCAL_OCCU : "GET_ALL_LOCAL_OCCU",
    GET_ALL_TYPE_LOCAL : "GET_ALL_TYPE_LOCAL",
    GET_SELECTED_LOCAL : "GET_SELECTED_LOCAL",
};
  
  