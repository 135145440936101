import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { routePaths } from "./routesPath";
import { UserSpace } from "@/pages/user-space/UserSpace";

export const protectedRoutes = [
    {
        path: routePaths.espace_user+'/*',
        element:
          <SafeErrorBoundaries> 
            <UserSpace />
          </SafeErrorBoundaries>,
     },
];
