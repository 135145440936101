
import { AppProvider } from "@/provider";
import AppRoutes from "@/routes";
import "leaflet/dist/leaflet.css";
import "./App.style.scss";

const App= () => {
  return (
    <>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </>
  );
};

export default App;