import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authTypes } from "./auth/auth.types";

import authReducer from "./auth/auth.reducers";
import compteReducer from "./compte/compte.reducers";
import profilReducer from "./profil/profil.reducers";
import siteReducer from "./site/site.reducers";
import batimentReducer from "./batiment/batiment.reducers";
import localReducer from "./local/local.reducers";
import directionReducer from "./direction/direction.reducers";
import poleReducer from "./pole/pole.reducers";
import communeReducer from "./commune/commune.reducers";
import gradeReducer from "./grade/grade.reducers";
import dashboardReducer from "./dashboard/dashboard.reducers";

export const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  blacklist: [],
};

const topReducer = combineReducers({
  auth: authReducer,
  compte: compteReducer,
  profil: profilReducer,
  site: siteReducer,
  batiment: batimentReducer,
  local: localReducer,
  direction: directionReducer,
  pole: poleReducer,
  commune: communeReducer,
  grade: gradeReducer,
  dashboard: dashboardReducer,
});

const rootReducer = (state, action) => {
  // when RESET action is dispatched it will reset redux state
  if (action.type === authTypes.LOGOUT) {
    state = undefined;
  }
  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
