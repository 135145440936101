import { apiCall } from "@/utils/api-call";
import { store } from "@/store";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { setLoadingAuth, getUserToken, getUser } from "../store/reducers/auth/auth.actions";


export class AuthService {

  static authenticate({email, password}) {

    apiCall.post('/auth', {login: email, password}).then((response)=>{
        //console.log(response);
        if(response.error){
           // console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{
            console.log("Not error ---> ");
            store.dispatch(getUserToken(response.data.token));
            store.dispatch(getUser(response.data));
            ToastGeneric('Valider avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoadingAuth(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }

  static forgottenPass({email}) {

    apiCall.post('/auth/forgot-password',{email}).then((response)=>{
       
        if(response.error){

            //console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la connexion!', 'error', 3000).fire();

        }else{

            //console.log("Not error ---> ");
            ToastGeneric('Email envoyé avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoadingAuth(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }

  static resetPass({password, resettoken}) {

    apiCall.post('/auth/reset-password',{password, resetToken: resettoken}).then((response)=>{
       
        if(response.error){

            //console.log("Error ---> ");
            ToastGeneric('Une erreur est survenue lors de la reinitialisation!', 'error', 3000).fire();

        }else{

            console.log("Not error ---> ");
            ToastGeneric('Mot de passe réinitialisé avec succès', 'success', 1000).fire();
        }
       store.dispatch(setLoadingAuth(false));
    }).catch((error)=>{
        if (isDev()) {
            console.error(error);
        }
        ToastGeneric('Erreur serveur!', 'error', 3000).fire();
        store.dispatch(setLoadingAuth(false));
    });
    
  }

}
