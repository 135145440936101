import { Routes, Route, Navigate } from "react-router-dom";
import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { lazyImport } from '@/utils/lazyImport';
import { routePaths } from "@/routes/routesPath";


const  Site  = lazyImport(
    () => import("@/pages/user-space/parametre/site/Site")
);

const  Batiment  = lazyImport(
    () => import("@/pages/user-space/parametre/batiment/Batiment")
);

const  Local  = lazyImport(
    () => import("@/pages/user-space/parametre/local/Local")
);

const  Pole  = lazyImport(
    () => import("@/pages/user-space/parametre/pole/Pole")
);

const  Direction  = lazyImport(
    () => import("@/pages/user-space/parametre/direction/Direction")
);

const  Commune  = lazyImport(
    () => import("@/pages/user-space/parametre/commune/Commune")
);

const  Personnel  = lazyImport(
    () => import("@/pages/user-space/parametre/personnel/Personnel")
);

export const ParametreRoutes = () => {
    return (
        <Routes>
            <Route path={routePaths.param_pole} element={
                <SafeErrorBoundaries>
                    <Pole />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.param_direction} element={
                <SafeErrorBoundaries>
                    <Direction />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.param_commune} element={
                <SafeErrorBoundaries>
                    <Commune />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.param_site} element={
                <SafeErrorBoundaries>
                    <Site />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.param_batiment} element={
                <SafeErrorBoundaries>
                    <Batiment />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.param_local} element={
                <SafeErrorBoundaries>
                    <Local />
                </SafeErrorBoundaries>
            } />
            {/* <Route path={routePaths.param_salle} element={
                <SafeErrorBoundaries>
                    <Salle />
                </SafeErrorBoundaries>
            } /> */}
            <Route path={routePaths.param_personnel} element={
                <SafeErrorBoundaries>
                    <Personnel />
                </SafeErrorBoundaries>
            } />
            <Route path='' element= {  <Navigate to={routePaths.param_pole}/>  } />
        </Routes>
    )
}