import { BASIC } from "./basic";

export const DARCULA = {
    ...BASIC,
    background: '#1c1d21',
    backgroundMiddle: '#292b2f',
    backgroundBorderPage: '#212327',
    backgroundStat: '#292b2f',
    colorStat: '#111',
    color: '#fff',
};