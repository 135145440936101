import Swal from "sweetalert2";
import '@/index.style.scss'

export const ToastSuccess = Swal.mixin({
  toast: true,
  position: "bottom-start",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  padding: "0.5rem",
  text: 'Opération exécutée avec succès!',
  icon: 'success',
});

export const ToastError = Swal.mixin({
  toast: true,
  position: "bottom-start",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  padding: "0.5rem",
  text: "Erreur survenue lors de l'opération!",
  icon: 'error',
});

export const ToastErrorFileType = Swal.mixin({
  toast: true,
  position: "bottom-start",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  padding: "0.5rem",
  text: "Mauvais type de fichier!",
  icon: 'error',
});

export const ToastGeneric = ( msg, type, timer) => {

  return Swal.mixin({
    toast: true,
    position: "bottom-start",
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
    padding: "0.5rem",
    text: msg,
    icon: type,
  });

} 
