import { batimentTypes } from "./batiment.types";

const INITIAL_STATE = {
  itemList: [],
  loading: false,
  itemSelected: {},
  loadingDetail: false,
  itemTypeList: [],
  typeLoading: false,
  itemTypeFonctionList: [],
  typeFonctionLoading: false,
  itemTypeProprieteList: [],
  typeProprieteLoading: false,
  errors: "",
};

const batimentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case batimentTypes.GET_ALL_BATIMENT:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case batimentTypes.BATIMENT_LOADING:
      return {
        ...state,
        typeLoading: action.payload,
        errors: "",
      };

    case batimentTypes.GET_SELECTED_BATIMENT:
      return {
        ...state,
        itemSelected: action.payload,
        loading: false,
      };

    case batimentTypes.BATIMENT_DETAIL_LOADING:
      return {
        ...state,
        loadingDetail: action.payload,
        errors: "",
      };

    case batimentTypes.GET_ALL_TYPE_BATIMENT:
      return {
        ...state,
        itemTypeList: action.payload,
        typeLoading: false,
      };

    case batimentTypes.BATIMENT_TYPE_LOADING:
      return {
        ...state,
        typeLoading: action.payload,
        errors: "",
      };

    case batimentTypes.GET_ALL_TYPE_FONCTION_BATIMENT:
      return {
        ...state,
        itemTypeFonctionList: action.payload,
        typeFonctionLoading: false,
      };

    case batimentTypes.BATIMENT_TYPE_FONCTION_LOADING:
      return {
        ...state,
        typeFonctionLoading: action.payload,
        errors: "",
      };

    case batimentTypes.GET_ALL_TYPE_PROPRIETE_BATIMENT:
      return {
        ...state,
        itemTypeProprieteList: action.payload,
        typeProprieteLoading: false,
      };

    case batimentTypes.BATIMENT_TYPE_PROPRIETE_LOADING:
      return {
        ...state,
        typeProprieteLoading: action.payload,
        errors: "",
      };

    default:
      return state;
  }
};

export default batimentReducer;
