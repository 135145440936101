import { createSelector } from "reselect";

export const selectAuth = (state) => state.auth;

export const selectAuthUser = createSelector(
  [selectAuth],
  (auth) => auth.user
);

export const selectAuthPass = createSelector(
  [selectAuth],
  (auth) => auth.password
);

export const selectAuthLoading = createSelector(
  [selectAuth],
  (auth) => auth.loading
);

export const selectAuthRememberMe = createSelector(
  [selectAuth],
  (auth) => auth.rememberMe
);

export const selectAuthErrors = createSelector(
  [selectAuth],
  (auth) => auth.errors
);

export const selectAuthToken = createSelector(
  [selectAuth],
  (auth) => auth.jwt
);


export const selectChoosenSkin = createSelector(
  [selectAuth],
  (auth) => auth.skin
);
