import { apiCall } from "./api-call";


export const setAuthToken = (token) => {
  if (token) {
    apiCall.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    delete apiCall.defaults.headers.common["Authorization"];
  }
};
