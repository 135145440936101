import { Routes, Route, Navigate } from "react-router-dom";
import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { routePaths } from "@/routes/routesPath";
import { lazyImport } from '@/utils/lazyImport';
import * as React from "react";

const  Signin  = lazyImport(
    () => import("@/pages/connection-space/signin/Signin")
);

const  Signup  = lazyImport(
    () => import("@/pages/connection-space/signup/Signup")
);

const  Forget  = lazyImport(
    () => import("@/pages/connection-space/forget/Forget")
);

const ResetPassword = lazyImport(
    () => import("@/pages/connection-space/reset_password/Reset_password")
)

export const ConnectionSpaceRoutes = () => {
    return (
        <Routes>
            <Route path={routePaths.signin} element={
                <SafeErrorBoundaries>
                    <Signin />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.signup} element={
                <SafeErrorBoundaries>
                    <Signup />
                </SafeErrorBoundaries>
            } />
            <Route path={routePaths.forget} element={
                <SafeErrorBoundaries>
                    <Forget />
                </SafeErrorBoundaries>
            } />
            <Route path={`${routePaths.reset_password}/:token`} element={
                <SafeErrorBoundaries>
                    <ResetPassword />
                </SafeErrorBoundaries>
            } />
            <Route path='' element= {  <Navigate to={routePaths.signin}/>  } />
        </Routes>
    )
}