import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import map from '../../assets/SVG/map.svg';
import home from '../../assets/SVG/dashboard.svg';
import space from '../../assets/SVG/SVG/espace.svg';
// import portal from '../../assets/SVG/portail.svg';
import account from '../../assets/SVG/compte.svg';
import setting from '../../assets/SVG/setting.svg';
import site from '../../assets/SVG/site.svg';
import pole from '../../assets/SVG/SVG/pole1.svg';
import direction from '../../assets/SVG/SVG/direction.svg';
import city from '../../assets/SVG/SVG/city.svg';
import batiment from '../../assets/SVG/batiment.svg';
import personnel from '../../assets/SVG/person.svg';
// import pole from '../../assets/SVG/salle.svg';
import local from '../../assets/SVG/local.svg';
import "./Sidebar.style.scss";
import Tooltip from '@mui/material/Tooltip';

import { Left } from '@/styles';

const items = localStorage.getItem("persist:root");
let data = JSON.parse(items);
let getUser = null
if (data) {
  getUser = JSON.parse(data?.auth);
}

const Sidebar = ({ collapse, getUser }) => {
  const navigate = useNavigate();

  const [openParametre, setOpenParametre] = useState(false);
  // const [viewSideBar]
  const handleParametreList = () => {
    setOpenParametre(!openParametre);
  };

  return (
    <Left>
    <div className={`list-part ${collapse ? "minimize" : ""}`}>
      <ul className={`list`}>
        <li
          className={`item  ${
            window.location.pathname === "/accueil/carte" ? "active" : ""
          }`}
        >
          <Tooltip title="Carte" placement="right" arrow>
          <Link className="link-item" to="/accueil/carte">
            <img className="map" src={map} alt="" />
            <span>CARTE</span>
          </Link>
          </Tooltip>
        </li>
        <li
          className={`item ${
            window.location.pathname === "/accueil/dashboard" ? "active" : ""
          }`}
        >
          <Tooltip title="Tableau de bord" placement="right" arrow>
          <Link className="link-item" to="/accueil/dashboard">
            <img className="home" src={home} alt="" />
            <span>TABLEAU DE BORD</span>
          </Link>
          </Tooltip>
        </li>
        <li
          className={`item ${
            window.location.pathname === "/accueil/gestion-espace" ? "active" : ""
          }`}
        >
          <Tooltip title="Gestion d'espace" placement="right" arrow>
          <Link className="link-item" to="/accueil/gestion-espace">
          <img className="space" src={space} alt="" />
            <span>Gestion d'espace</span>
          </Link>
          </Tooltip>
        </li>
        <li
          className={`item ${
            window.location.pathname.includes("/accueil/compte") ? "active" : ""
          }`}
        >
          <Tooltip title="Compte" placement="right" arrow>
          <Link className="link-item" to="/accueil/compte">
            <img className="account" src={account} alt="" />
            <span>Compte</span>
          </Link>
          </Tooltip>
        </li>
        <li
          className={`item ${
            window.location.pathname.includes("/accueil/parametre") ? "active" : ""
          } ${openParametre ? "open" : ""}`}
        >
          <div className="link-item" onClick={handleParametreList}>
            <Tooltip title="Paramètre" placement="bottom" arrow>
            <div>
            <img className="setting" src={setting} alt="" />
            <span style={{marginRight: "30px"}}>Paramètre</span>
            </div>
            </Tooltip>
          </div>
          {openParametre && (
            <ul>
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/pole" ? "active" : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/pole">
                  <img className="site" src={pole} alt="" />
                  <span>Pôle</span>
                </Link>
              </li>
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/direction" ? "active" : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/direction">
                  <img className="site" src={direction} alt="" />
                  <span>Direction</span>
                </Link>
              </li>
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/commune" ? "active" : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/commune">
                  <img className="site" src={city} alt="" />
                  <span>Commune</span>
                </Link>
              </li>
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/site" ? "active" : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/site">
                  <img className="site" src={site} alt="" />
                  <span>Site</span>
                </Link>
              </li>
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/batiment"
                    ? "active"
                    : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/batiment">
                  <img className="batiment" src={batiment} alt="" />
                  <span>Batiment</span>
                </Link>
              </li>
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/local"
                    ? "active"
                    : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/local">
                  <img className="batiment" src={local} alt="" />
                  <span>Local</span>
                </Link>
              </li>
               {/* <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/salle"
                    ? "active"
                    : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/salle">
                  <img className="batiment" src={salle} alt="" />
                  <span>Salle</span>
                </Link>
              </li> */}
              <li
                className={`submenu ${
                  window.location.pathname === "/accueil/parametre/personnel"
                    ? "active"
                    : ""
                }`}
              >
                <Link className="link-item" to="/accueil/parametre/personnel">
                  <img className="batiment" src={personnel} alt="" />
                  <span>Grade</span>
                </Link>
              </li> 
            </ul>
          )}
        </li>
      </ul>
    </div>
    </Left>
  );
};

export default Sidebar;
