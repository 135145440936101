import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "@/store";
import "./provider.style.scss";

export const AppProvider = ({ children }) => {
  return (

      <Provider store={store}>
        <Router>{children}</Router>
      </Provider>
    
  );
};
