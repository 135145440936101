import * as React from "react";
import { UserSpaceRoutes } from "./routes";
import "./UserSpace.style.scss";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import {ThemeProvider} from 'styled-components';
import {getTheme} from "@/getTheme";

import { selectChoosenSkin } from "@/store/reducers/auth/auth.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

//components
import Navbar from "@/components/navbar/Navbar.jsx";
import Sidebar from "@/components/sidebar/Sidebar.jsx";

export const UserSpacePage = ({theme}) => {
  const [full, setFull] = React.useState(false);
  const [collapse, setCollapse] = React.useState(false);
  
  return (
    <ThemeProvider theme={getTheme(theme)}>
    <div className={`userspace ${full ? "fullscreen" : ""}`}>
      {/* Navbar */}
      <div className="header">
        <Navbar setCollapse={setCollapse} collapse={collapse} />
      </div>
      {/* content */}
      <div className={`body ${collapse ? "colapse" : ""}`}>
        {/* sidebar */}
        <div className="sidebar">
          <Sidebar collapse={collapse} />
        </div>
        {/* page */}
        <div className="page">
          <React.Suspense
            fallback={
              <div className="loading-screen">
                <Box
                  sx={{
                    position: 'relative',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "96%",
                    height: "100%",
                    overflow: "hidden"
                  }}
                >
                  <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "10%",
                    overflow: "hidden"
                    }}
                  >
                    <Skeleton animation="wave" variant="text" width="30%" height="100%" />
                  </Box>
                  <Skeleton animation="wave" variant="rectangular" width="100%" height="90%" />
                  {/* <Skeleton variant="text" width="100%" height="10%" /> */}
                </Box>
              </div>
            }
          >
            <UserSpaceRoutes setFull={setFull} />
          </React.Suspense>
        </div>
      </div>
    </div>
    </ThemeProvider>
  );
};

const mapStateToProps = createStructuredSelector({
  theme: selectChoosenSkin,
});

const connector = connect(mapStateToProps);
export const UserSpace = connector(UserSpacePage);
