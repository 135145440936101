export const batimentTypes = {
    BATIMENT_LOADING : "BATIMENT_LOADING",
    BATIMENT_DETAIL_LOADING : "BATIMENT_DETAIL_LOADING",
    BATIMENT_CREATE_LOADING : "BATIMENT_CREATE_LOADING",
    BATIMENT_TYPE_LOADING : "BATIMENT_TYPE_LOADING",
    BATIMENT_TYPE_PROPRIETE_LOADING : "BATIMENT_TYPE_PROPRIETE_LOADING",
    BATIMENT_TYPE_FONCTION_LOADING : "BATIMENT_TYPE_FONCTION_LOADING",
    BATIMENT_CREATE : "BATIMENT_CREATE",
    GET_ALL_BATIMENT : "GET_ALL_BATIMENT",
    GET_ALL_TYPE_BATIMENT : "GET_ALL_TYPE_BATIMENT",
    GET_ALL_TYPE_PROPRIETE_BATIMENT : "GET_ALL_TYPE_PROPRIETE_BATIMENT",
    GET_ALL_TYPE_FONCTION_BATIMENT : "GET_ALL_TYPE_FONCTION_BATIMENT",
    GET_SELECTED_BATIMENT : "GET_SELECTED_BATIMENT",
    GET_SELECTED_TYPE_BATIMENT : "GET_SELECTED_TYPE_BATIMENT",
    GET_SELECTED_TYPE_PROPRIETE_BATIMENT : "GET_SELECTED_TYPE_PROPRIETE_BATIMENT",
    GET_SELECTED_TYPE_FONCTION_BATIMENT : "GET_SELECTED_TYPE_FONCTION_BATIMENT",
};