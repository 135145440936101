import styled from 'styled-components';

export const Header = styled.div `
	background-color: ${props => props.theme.background};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.color};
`;

export const Left = styled.div `
	background-color: ${props => props.theme.background};
  height: 100%;
  width: 100%;
  color: ${props => props.theme.color};
`;

export const Middle = styled.div `
	background-color: ${props => props.theme.backgroundMiddle};
  height: 100%;
  width: 100%;
  color: ${props => props.theme.color};
`;

export const titleChange = styled.div `
  color: ${props => props.theme.color};
`;

export const Center = styled.div `
	background-color: ${props => props.theme.backgroundBorderPage};
  height: 100%;
  width: 100%;
  color: ${props => props.theme.color};
`;

export const Graphe = styled.div `
	background-color: ${props => props.theme.backgroundBorderPage};
  height: 100%;
  width: 100%;
  display flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.color};
`;

export const Stat = styled.div `
  position: absolute;
  top: 0;
  left: 4.5%;
  width: 90%;
  background: ${props => props.theme.backgroundStat};
  color: ${props => props.theme.colorStat};
`;

export const Paginate = styled.div `
  color: ${props => props.theme.color};
`;

export const AppLink = styled.a `
	color: ${props => props.theme.linkColor};
`;