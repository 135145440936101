export const communeTypes = {
    COMMUNE_LOADING : "COMMUNE_LOADING",
    COMMUNE_DETAIL_LOADING : "COMMUNE_DETAIL_LOADING",
    COMMUNE_CREATE_LOADING : "COMMUNE_CREATE_LOADING",
    COMMUNE_CREATE : "COMMUNE_CREATE",
    GET_ALL_COMMUNE : "GET_ALL_COMMUNE",
    GET_SELECTED_COMMUNE : "GET_SELECTED_COMMUNE",
    
};
  
  