import { poleTypes } from "./pole.types";

const INITIAL_STATE = {
  itemList: [],
  loading: false,
  itemSelected: {},
  // loadingDetail: false,
  errors: "",
};

const poleReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case poleTypes.GET_ALL_POLE:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case poleTypes.POLE_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case poleTypes.GET_SELECTED_POLE:
      return {
        ...state,
        itemSelected: action.payload,
        loading: false,
      };

    // case poleTypes.POLE_DETAIL_LOADING:
    //   return {
    //     ...state,
    //     loadingDetail: action.payload,
    //     errors: "",
    //   };

    default:
      return state;
  }
};

export default poleReducer;
