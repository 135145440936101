import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { routePaths } from "./routesPath";
import { ConnectionSpace } from "@/pages/connection-space/ConnectionSpace";


export const publicRoutes = [
  {
    path: routePaths.espace_connexion+'/*',
    element:
      <SafeErrorBoundaries> 
        <ConnectionSpace />
      </SafeErrorBoundaries>,
  },
];
