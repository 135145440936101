import { compteTypes } from "./compte.types";

const INITIAL_STATE = {
  itemList: [],
  itemSelected: {},
  loading: false,
  errors: "",
};

const compteReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case compteTypes.GET_ALL_COMPTE:
      return {
        ...state,
        itemList: action.payload,
        loading: false,
      };

    case compteTypes.COMPTE_LOADING:
      return {
        ...state,
        loading: action.payload,
        errors: "",
      };

    case compteTypes.GET_SELECTED_COMPTE:
      return {
        ...state,
        itemSelected: action.payload,
        errors: "",
      };

    default:
      return state;
  }
};

export default compteReducer;
