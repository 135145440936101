import React, {useState} from "react";
import powerIcon from "@/assets/mapIcon/power.svg";
import {Button,Tooltip} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import burger from "@/assets/mapIcon/burger.svg";
import burgerfull from "@/assets/mapIcon/burgerfull.svg";
import { useNavigate } from "react-router-dom";
import "./Navbar.style.scss";
import avatar from "@/assets/SVG/avatar_user.svg";
import light from "@/assets/sun.png";
import dark from "@/assets/night.png";
import { store } from '@/store';
import { selectChoosenSkin } from "@/store/reducers/auth/auth.selectors";
import { selectSkin } from "@/store/reducers/auth/auth.actions";
import { logoutAsync } from "@/store/reducers/auth/auth.actions";
import { selectAuthUser, selectAuthPass } from "@/store/reducers/auth/auth.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
// import THEMES from '@/constants/themes';
import { Header } from '@/styles';

const NavbarPage = ({
  setCollapse,
  collapse,
  theme,
  user,
  getOutAsync,
}) => {
  
  useNavigate();
  const [isOff, setIsOff] = useState(false)
  
  const logout = () => {
    setIsOff(true);
    setTimeout(() => {
      setIsOff(false);
      getOutAsync();
    }, 2000);
  };

  const handleSkin = () => {
    if (theme === "darcula") {
      store.dispatch(selectSkin("apple"));
    } else {
      store.dispatch(selectSkin("darcula"));
    }
  }  

  return (
    <Header>
    <div className="main-nav">
      <div className="part-nav-left">
        <div className="logo">
          <div className="logo-main">
            <div className="logo-letter">GP</div>
            <div className="logo-name">
              <div className="logo-name1">GESTION</div>
              <div className="logo-name2">PATRIMOINE</div>
            </div>
          </div>
        </div>
        
        <div
          className="btnCol"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {collapse ? (
            <Tooltip title="Etendre le menu latéral" placement="bottom" arrow>
            <Button color="warning">
              {" "}
              <img src={burger} alt="" />{" "}
            </Button>
            </Tooltip>
            
          ) : (
            <Tooltip title="Raccourcir le menu latéral" placement="bottom" arrow>
            <Button color="warning">
              {" "}
              <img src={burgerfull} alt="" />{" "}
            </Button>
            </Tooltip>
            
          )}
        </div>
      </div>
      <div className="part-nav-right">
        <Tooltip title="Gestion du thème" placement="bottom" arrow>
        <Button className="notification" onClick={handleSkin}>
          {(theme === "darcula") ? (
            <img src={light} alt="" height={30} />
          ) : (
            <img src={dark} alt="" height={30} />
          )}
        </Button>
        </Tooltip>
        <div className="log">
          <div className="avatar">
            <img src={avatar} alt="" />
          </div>
          <div className="user">
            <div className="username">
              <span>
                {user.nom.toUpperCase()} {user.prenoms.toUpperCase()}{" "}
              </span>
            </div>
            <div className="job">
              <span>{user.profil.libelle}</span>
            </div>
          </div>
        </div>
                
        {
          isOff ? 
          <LoadingButton loading className="disconnect" onClick={logout}>
            <img src={powerIcon} alt="" height="30px" />
          </LoadingButton>
          :
          <Button className="disconnect" onClick={logout}>
            <img src={powerIcon} alt="" height="30px" />
          </Button>
        }
        
      </div>
    </div>
    </Header>
  )
};

const mapStateToProps = createStructuredSelector({
  user: selectAuthUser,
  password: selectAuthPass,
  theme: selectChoosenSkin
});

const mapDispatchToProps = (dispatch) => ({
  getOutAsync: () => dispatch(logoutAsync()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Navbar = connector(NavbarPage);

export default Navbar;
